import React from "react"
import {isLoggedIn} from "../../services/auth";
import { Link, navigate } from "gatsby"
import { getUser } from "../../services/auth"

import goldLogo from '../../images/gold_logo.png';

const AdminNav = () => (
    <>
        <nav id="nav-desktop" className={"nav nav-admin uk-navbar uk-navbar-container"} uk-navbar={"true"}>
            <div className="uk-navbar-left">
                <Link className={"site-logo"} to={'/admin'}><img src={goldLogo} alt="Smoky Mountain Distributors"/></Link>
            </div>
            <div className="uk-navbar-right">
                <ul className={"nav-top uk-navbar-nav"}>
                    <li></li>

                    <li>
                        <a href="#">Tour Management <span uk-icon={"icon: chevron-down"}></span></a>
                        <div className={"uk-navbar-dropdown uk-navbar-dropdown-bottom-left"}>
                            <ul className={"uk-nav uk-navbar-dropdown-nav"}>
                                <li><Link to={"/admin/tour/list"}>View Tours</Link></li>
                            </ul>
                        </div>

                    </li>

                    <li>
                        <a href="#">Employee Management <span uk-icon={"icon: chevron-down"}></span></a>
                        <div className={"uk-navbar-dropdown uk-navbar-dropdown-bottom-left"}>
                            <ul className={"uk-nav uk-navbar-dropdown-nav"}>
                                <li><Link to={"/admin/employee/list"}>View Employees</Link></li>
                            </ul>
                        </div>

                    </li>
                    <li>
                        <a href="#">Help <span uk-icon={"icon: chevron-down"}></span></a>
                        <div className={"uk-navbar-dropdown uk-navbar-dropdown-bottom-left"}>
                            <ul className={"uk-nav uk-navbar-dropdown-nav"}>
                                <li><Link to={"/admin/help"}>Help Page</Link></li>
                            </ul>
                        </div>

                    </li>
                </ul>
            </div>
        </nav>

        <div className="uk-position-relative">

            <nav id="nav-mobile" className={"nav nav-admin uk-navbar uk-navbar-container"} uk-navbar={"dropbar: +.uk-navbar-dropbar"}>
                <div className="uk-navbar-left">
                    <Link to={'/admin'}><img className={"site-logo"} src={goldLogo} alt="Smoky Mountain Distributors"/></Link>
                </div>
                <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                        <li>

                            <a href="#"><span uk-icon={"icon: menu"}></span></a>

                            <div className="uk-navbar-dropdown uk-navbar-dropdown-dropbar uk-navbar-dropdown-width-2">
                                <div className="uk-navbar-dropdown-grid uk-child-width-1-2 uk-grid" uk-grid>
                                    <div className="uk-first-column">
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            <li>
                                                <span>Tour Management</span>
                                                <ul className={"uk-nav-sub"}>
                                                    <li><Link to={"/admin/tour/list"}>View Tours</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <span>Employee Management</span>
                                                <ul className={"uk-nav-sub"}>
                                                    <li><Link to={"/admin/employee/list"}>View Employees</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className={"uk-nav uk-navbar-dropdown-nav"}>
                                            <li>
                                                <span>Help</span>
                                                <ul className={"uk-nav-sub"}>
                                                    <li><Link to={"/admin/help"}>Help Page</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="uk-navbar-dropdown-grid uk-child-width-1-2 uk-grid" uk-grid>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </nav>
            <div className="uk-navbar-dropbar uk-navbar-dropbar-slide">

            </div>
        </div>
    </>
)

export default AdminNav

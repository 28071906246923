import React from "react"
import { getUser } from "../../services/auth"

const AdminDashboard = () => (
    <>
        <h1 >Admin Dashboard</h1>

        <p>Signed in as <strong>{ getUser().user.email }</strong></p>

        <hr className="uk-divider-icon"/>

        <p>To navigate, use the menu up top.  For support, please contact <strong>Nick Wellman.</strong></p>

        <hr className="uk-divider-icon"/>
        <h2>Updates and News</h2>
        <ul uk-accordion={"collapsible: false"}>
            <li className={"uk-open"}>
                <a href="#" className="uk-accordion-title">July 20, 2021</a>
                <div className="uk-accordion-content">
                    <ul>
                        <li>Application launched</li>
                        <li>Updates to animations on Slide 3</li>
                    </ul>
                </div>
            </li>
            <li>
                <a href="#" className="uk-accordion-title">July 15, 2021</a>
                <div className="uk-accordion-content">
                    <ul>
                        <li>Application launched</li>
                        <li>Updates to animations on Slide 3</li>
                    </ul>
                </div>
            </li>
        </ul>

    </>
)

export default AdminDashboard

import React from "react"
import { Router } from "@reach/router"
import AdminLayout from "../components/adminLayout";
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import AdminDashboard from "../components/admin/adminDashboard"
import TourList from "../components/admin/tour/tourList";
import EmployeeList from "../components/admin/employee/employeeList";
import Login from "../components/login"
import AdminHelp from "../components/admin/help/adminHelp";

const Admin = () => (
    <AdminLayout>
        <Router>
            <PrivateRoute path="/admin" component={AdminDashboard} />
            <PrivateRoute path="/admin/profile" component={Profile} />

            <PrivateRoute path="/admin/tour/list" component={TourList} />
            <PrivateRoute path="/admin/employee/list" component={EmployeeList} />

            <PrivateRoute path="/admin/help" component={AdminHelp} />

            <Login path="/admin/login" />
        </Router>
    </AdminLayout>
)

export default Admin
import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import {isBrowser} from "../../../services/auth";
import { smdApiRequest } from "../../../services/smdRequest";
import Modal from "react-modal";



const EmployeeList = ({ children, seo }) => {

    const [modalOpen, setModalOpen] = useState(false);

    const [employees, setEmployees] = useState([]);

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');

    function getAllEmployees() {
        smdApiRequest('/api/employee/all')
        .then(response => response.json())
        .then(json => setEmployees(json.data))
        .catch(e => alert(e.message));
    }

    async function postEmployee(event) {
        event.preventDefault();

        let response = await smdApiRequest('/api/employee', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                title: title,
                data: {}
            })
        });

        response = await response.json();

        return response;
    }


    useEffect(() => {
        getAllEmployees();
    }, []);

    return (
        <>
            <h1>Employee List</h1>
            <button className={"uk-button uk-button-primary"} onClick={event => setModalOpen(true)}>+ New Employee</button>
            <table className={"uk-table uk-table-striped uk-overflow-auto uk-table-hover"}>
                <caption>Employees listed below</caption>
                <thead>
                <tr>
                    <th>Employee ID</th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Created</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    employees && employees.map(item => {

                        return (
                            <tr key={ item._id }>
                                <td>{ item._id.substring('-1', 8) }</td>
                                <td>{ item.name }</td>
                                <td>{ item.title }</td>
                                <td>{ new Date(item.created).toLocaleString() }</td>
                                <td>
                                    <div className={"uk-flex uk-flex-row uk-grid-small"} uk-grid>
                                        <div className="">
                                            <a className={"uk-button uk-button-text"}
                                                onClick={async function (event) {
                                                    if (isBrowser() && window.confirm('Are you sure you want to delete this tour?')) {
                                                        let response = await smdApiRequest('/api/tour', {
                                                            method: "DELETE",
                                                            headers: {
                                                                "Content-Type": "application/json"
                                                            },
                                                            body: JSON.stringify({
                                                                tourId: this._id,
                                                            })
                                                        });
                                                        response = await response.json();
                                                        alert(response.message);
                                                    }
                                                }.bind(item)}
                                            ><span uk-icon={"icon: pencil"}></span></a>
                                            <a class={"uk-button uk-button-text"} onClick={async function(event) {
                                                if (isBrowser() && window.confirm('Are you sure you want to delete this employee?')) {
                                                    let response = await smdApiRequest('/api/employee', {
                                                        method: "DELETE",
                                                        headers: {
                                                            "Content-Type": "application/json"
                                                        },
                                                        body: JSON.stringify({
                                                            employeeId: this._id,
                                                        })
                                                    });
                                                    response = await response.json();
                                                    alert(response.message);
                                                    getAllEmployees();
                                                }
                                            }.bind(item)}
                                            ><span uk-icon={"icon: trash"}></span></a>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )

                    })
                }
                </tbody>
            </table>

            <Modal
                isOpen={modalOpen}
            >
                <header>
                    <span>Create Employee</span>
                    <input type="button" value={'X'} onClick={ev => setModalOpen(false)}/>
                </header>
                <div className="smd-modal-body">
                    <form id="new-employee-form" className={"form uk-grid-small"} uk-grid>
                        <div className={"form-row uk-form-stacked"}>
                            <label htmlFor="employee" className="uk-form-label">Employee Name</label>
                            <input name="employee" id="employee" className="uk-select" value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>

                        <div className={"form-row"}>
                            <label htmlFor="customer">Title</label>
                            <input type="text" className="uk-input" value={title}
                                onChange={e => setTitle(e.target.value)}/>
                        </div>

                    </form>
                </div>

                <div className="smd-modal-actions">
                    <input
                        type="button"
                        value={"Create employee"}
                        className={'uk-input uk-button uk-button-primary'}
                        onClick={async (ev) => {
                            let response = await postEmployee(ev);
                            alert(response.message);
                            setModalOpen(false);
                            getAllEmployees();
                        }}
                    />


                </div>
            </Modal>


            <div id="new-tour" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body">
                    <h2 className="uk-modal-title">Add New Tour</h2>

                </div>
            </div>
        </>
    )
}


export default EmployeeList

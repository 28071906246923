import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import {isBrowser} from "../../../services/auth";
import { smdApiRequest } from "../../../services/smdRequest";
import Modal from "react-modal";

const TourList = ({ children, seo }) => {

    const [modalOpen, setModalOpen] = useState(false);

    const [tours, setTours] = useState([]);

    const [employees, setEmployees] = useState([]);

    const [employee, setEmployee] = useState('');
    const [customer, setCustomer] = useState('');

    function getAllTours() {

        smdApiRequest('/api/tour/all', {})
            .then(response => response.json())
            .then((json) => {
                setTours(json.data);
            });

    }

    function getAllEmployees() {
        smdApiRequest('/api/employee/all')
            .then(response => response.json())
            .then(json => setEmployees(json.data))
            .catch(e => console.log(e));
    }

    async function postTour(event) {
        event.preventDefault();

        let response = await smdApiRequest('/api/tour', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                employeeId: employee,
                setId: 0,
                customerName: customer,
                data: {}
            })
        });

        response = await response.json();

        return response;
    }


    useEffect(() => {
        getAllEmployees();
        getAllTours();
    }, []);

    return (
        <>
            <h1>Tour List</h1>
            <button className={"uk-button uk-button-primary"} onClick={event => setModalOpen(true)}>+ New Tour</button>
            <table className={"uk-table uk-table-striped uk-overflow-auto uk-table-hover"}>
                <caption>Active Tours are listed below</caption>
                <thead>
                    <tr>
                        <th>Tour ID</th>
                        <th>Customer</th>
                        <th>Tour Guide</th>
                        <th>Start Time</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tours && tours.map(item => {

                            return (
                                <tr key={ item._id }>
                                    <td>{ item._id.substring('-1', 8) }</td>
                                    <td>{ item.customerName }</td>
                                    <td>{ item.employeeName }</td>
                                    <td>{ new Date(item.created).toLocaleString() }</td>
                                    <td>
                                        <div className={"uk-flex uk-flex-row uk-grid-small"} uk-grid>
                                            <div className="">
                                                <a class={"uk-button uk-button-text"} onClick={async function(event) {
                                                    if (isBrowser() && window.confirm('Are you sure you want to delete this tour?')) {
                                                        let response = await smdApiRequest('/api/tour', {
                                                            method: "DELETE",
                                                            headers: {
                                                                "Content-Type": "application/json"
                                                            },
                                                            body: JSON.stringify({
                                                                tourId: this._id,
                                                            })
                                                        });
                                                        response = await response.json();
                                                        alert(response.message);
                                                        getAllTours();
                                                    }
                                                }.bind(item)}
                                                ><span uk-icon={"icon: trash"}></span></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )

                        })
                    }
                </tbody>
            </table>

            <Modal
                isOpen={modalOpen}
            >
                <header>
                    <span>Create Tour</span>
                    <input type="button" value={'X'} onClick={ev => setModalOpen(false)}/>
                </header>
                <div className="smd-modal-body">
                    <form id="new-tour-form" className={"form uk-grid-small"} uk-grid onSubmit={postTour}>
                        <div className={"form-row uk-form-stacked"}>
                            <label htmlFor="employee" className="uk-form-label">Employee</label>
                            <select name="employee" id="employee" className="uk-select" value={employee ?? employees[0]._id}

                                onChange={e => setEmployee(e.target.value)}
                            >
                                <option value="0">Select an employee</option>
                                {
                                    employees && employees.map(employee => {

                                        return (
                                            <option key={employee._id} value={employee._id}>{employee.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className={"form-row"}>
                            <label htmlFor="customer">Customer</label>
                            <input type="text" className="uk-input" value={customer}
                                onChange={e => setCustomer(e.target.value)}/>
                        </div>

                    </form>
                </div>

                <div className="smd-modal-actions">
                    <input
                        type="button"
                        value={"Create tour"}
                        className={'uk-input uk-button uk-button-primary'}
                        onClick={async (ev) => {
                            let response = await postTour(ev);
                            alert(response.message);
                            setModalOpen(false);
                            getAllTours();
                        }}
                    />


                </div>
            </Modal>


            <div id="new-tour" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body">
                    <h2 className="uk-modal-title">Add New Tour</h2>

                </div>
            </div>
        </>
    )
}


export default TourList
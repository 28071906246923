/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import AdminNav from "./admin/adminNav";
import UIkit from "uikit";
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

const Layout = ({ children, seo }) => (
    <StaticQuery
        query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              url
            }
          }
        }
      }
    `}
        render={(data) => (
            <div className={"admin-page"}>
                <AdminNav></AdminNav>
                <main>{children}</main>
            </div>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;

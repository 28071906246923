import React from "react"
import { navigate } from "gatsby"
import {handleLogin, isBrowser, isLoggedIn} from "../services/auth"

class Login extends React.Component {
    state = {
        username: ``,
        password: ``,
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()

        try {
            await handleLogin(this.state)
        }
        catch (e) {
            alert(e.message);
        }
    }

    render() {
        if (isBrowser() && isLoggedIn()) {
            navigate(`/admin/profile`)
        }

        return (
            <>
                <h1>Log in</h1>
                <form
                    className={"form"}
                    onSubmit={async event => {
                        await this.handleSubmit(event)
                        if (isBrowser() && isLoggedIn()) {
                            navigate(`/admin`)
                            alert("Login successful");
                        }
                    }}
                >
                    <div className="form-row">
                        <label className={'uk-form-label'}>Email Address</label>
                        <input type="text" name="username" className={"uk-input"} onChange={this.handleUpdate} />
                    </div>
                    <div className="form-row">

                    <label className={'uk-form-label'}>Password</label>
                    <input
                        type="password"
                        className={"uk-input"}
                        name="password"
                        onChange={this.handleUpdate}
                    />
                    </div>
                    <input type="submit" value="Log In" className={"uk-button uk-button-primary"} />
                </form>
            </>
        )
    }
}

export default Login
import React, { useState, useEffect } from "react"
import {graphql, useStaticQuery} from "gatsby";
import ReactMarkdown from "react-markdown";


const query = graphql`
  query {
    allStrapiHelpPage(sort: {fields: Order}) {
      edges {
        node {
          id
          Category
          Content
          Title
          Order
        }
      }
    }
  }
`;

export default function AdminHelp() {
    const helpPages = useStaticQuery(query);

    console.log(helpPages);
    let data = helpPages.allStrapiHelpPage.edges;

    return (
        <>
            <h2>Help Pages</h2>
            <p>
                Please check below for guidance on common tasks and FAQs. If you need further assistance, please contact
                Nick Wellman for additional support.
            </p>
            <ul uk-accordion={"collapsible: false"}>
                { data && data.map((item, index) => {
                    return (
                        <li className={ index == 0 ? "uk-open" : ''} key={index}>
                            <a href="#" className="uk-accordion-title">{ item.node.Order }.) { item.node.Title }</a>
                            <div className="uk-accordion-content">
                                <ReactMarkdown children={item.node.Content}></ReactMarkdown>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

